<template>
	<div class="addon">
		<div class="block">
			<div class="item index">{{ index + 1 }}</div>

			<div class="item drag-addon">
				<i class="el-icon-rank" />
			</div>

			<div class="item skulist">
				<Input
					size="small"
					placeholder="Sku list"
					:value="data.sku_list"
					@input="update({sku_list: $event})"
				/>
			</div>

			<div class="item altTitle">
				<Input
					size="small"
					placeholder="Alternative title"
					:value="data.alternative_title"
					@input="update({alternative_title: $event})"
				/>
			</div>

			<div class="item view">
				<Button size="mini" icon="el-icon-view" @click="view" />
			</div>

			<div class="item quantity">
				<InputNumber
					size="mini"
					controls-position="right"
					v-model="data.quantity"
					@change="update({quantity: $event})"
				/>
			</div>

			<div class="item remove">
				<a class="link" @click="remove">
					<i class="el-icon-delete" />
				</a>
			</div>
		</div>

		<div class="block">
			<div class="item stockitem">
				<stockItem
					placeholder="Stock item"
					:value="data.stock_item"
					@input="updateStockItem"
				/>
			</div>
		</div>

		<div class="block">
			<div class="item materials">
				<materials
					:value="data.materials"
					@input="updateMaterials"
					:addonIndex="index"
					:addon="true"
					:item="item"
					:sort="true"
				/>
			</div>
		</div>

		<div class="block">
			<div class="item parts">
				<parts :value="data.parts" @input="updateParts" />
			</div>
		</div>
	</div>
</template>

<script>
import {InputNumber, Input, Button} from "element-ui";
import stockItem from "@/components/stock-item.vue";
import materials from "@/components/materials.vue";
import parts from "@/components/parts.vue";
import {debounce, cloneDeep} from "lodash";

export default {
	components: {stockItem, materials, parts, InputNumber, Input, Button},
	props: {
		index: {type: Number, required: true},
		item: {type: Object, required: true}
	},
	data: (t) => ({
		payload: {},
		data: cloneDeep(t.item)
	}),
	methods: {
		view() {
			this.$router.push(`/t/stock-items/${this.data.stock_item.id}`);
		},

		async remove() {
			await this.$store.dispatch("pricelistGroup/removeAddon", {
				id: this.data.id
			});
		},

		async updateStockItem({id, material_id}) {
			const materials = [{id: material_id}].filter((x) => x.id !== null);
			this.update({}, {materials, stock_item_id: id});
		},

		async updateMaterials(materials) {
			await this.update({materials});
		},

		async updateParts(parts) {
			await this.update({parts});
		},

		update(data, payload) {
			this.payload = {...this.payload, ...(payload || data)};
			this.data = {...this.data, ...data};
			this.updateDeb();
		},

		updateDeb: debounce(async function() {
			await this.$store.dispatch("pricelistGroup/updateAddon", {
				data: this.payload,
				id: this.data.id
			});

			this.payload = {};
		}, 700)
	}
};
</script>

<style lang="scss" scoped>
.addon {
	padding: 0.25em 0;

	.block {
		display: flex;
		align-items: center;
		margin: 0.5em 0;
		margin-left: 2.65em;

		&:nth-child(1) {
			margin-left: 0;
		}
	}

	.item {
		margin-right: 0.5em;

		&.index {
			flex-shrink: 0;
			width: 10px;
			font-size: 12px;
			color: #c0ccda;
		}

		&.drag-addon {
			margin-right: 10px;
		}

		&.skulist {
			flex-shrink: 0;
			width: 310px;
		}

		&.stockitem {
			width: 100%;
			max-width: 100%;
			zoom: 0.9;
		}

		&.materials {
			width: 100%;
			zoom: 0.9;
		}

		&.quantity {
			z-index: 0;
			flex-shrink: 0;
			width: 5em;

			> div {
				width: 100%;
			}
		}

		&.remove {
			flex-shrink: 0;
			margin-left: 0.5em;
			color: #c0ccda;
		}

		&.altTitle {
			flex-shrink: 0;
			width: 310px;
		}

		&.parts {
			width: 100%;
			zoom: 0.9;
		}
	}
}
</style>
