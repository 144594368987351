<template>
	<layout v-if="data" class="group">
		<template slot="header">
			<div class="title">
				<router-link
					class="back"
					:to="{name: 'pricelist', params: $route.params}"
				>
					<i class="el-icon-arrow-left" />
				</router-link>

				<div class="text">
					<span class="pricelist" v-text="data.price_list.name" />
					{{ data.name }}
				</div>
			</div>
		</template>

		<div class="main" slot="main">
			<div class="box-header">
				<div class="title">Pricelist group lines</div>
				<create />
			</div>

			<lines v-bind="data" />
		</div>

		<div class="sidebar" slot="sidebar">
			<settings v-bind="data" />
		</div>
	</layout>
</template>

<script>
import layout from "@/components/layout.vue";
import settings from "./settings.vue";
import create from "./create.vue";
import lines from "./lines";

export default {
	components: {layout, settings, create, lines},
	computed: {
		data: (t) => t.$store.getters["pricelistGroup/data"]
	},
	created() {
		this.$store.dispatch("pricelist/languages");
		this.$store.dispatch("pricelistGroup/data", {
			id: this.$route.params.groupId
		});
	},
	beforeDestroy() {
		this.$store.commit("pricelistGroup/data", null);
	}
};
</script>

<style lang="scss" scoped>
.back {
	margin-right: 0.5em;
}
.pricelist {
	color: #b7bcc2;
	margin-right: 0.5em;
}
</style>
