<template>
	<div class="line">
		<div class="line-item">
			<div class="top">
				<div class="drag-line">
					<i class="el-icon-rank" />
				</div>

				<div class="item skulist">
					<Input
						size="small"
						:value="data.sku_list"
						@input="update({sku_list: $event})"
					/>
				</div>

				<div class="item stockitem">
					<stockItem :value="data.stock_item" @input="updateStockItem" />
				</div>

				<div class="item view">
					<Button size="mini" icon="el-icon-view" @click="view" />
				</div>

				<div class="item quantity">
					<InputNumber
						size="mini"
						controls-position="right"
						v-model="data.quantity"
						@change="update({quantity: $event})"
					/>
				</div>

				<div class="item addon">
					<Button size="mini" @click="addAddon">Addon</Button>
				</div>

				<div class="item break">
					<Tooltip>
						<div slot="content">Page break before</div>
						<Checkbox
							v-model="data.page_break_before"
							@change="update({page_break_before: $event})"
						/>
					</Tooltip>
				</div>

				<div class="item show-materials">
					<Tooltip>
						<div slot="content">Show materials section</div>
						<Checkbox
							v-model="data.show_material_section"
							@change="update({show_material_section: $event})"
						/>
					</Tooltip>
				</div>

				<div class="item remove">
					<a class="link" @click="remove">
						<i class="el-icon-delete" />
					</a>
				</div>
			</div>

			<div class="bottom">
				<div class="item coverId">
					<stockItem
						:allowEmpty="true"
						placeholder="Cover"
						:value="data.cover"
						@input="updateCoverId"
					/>
				</div>

				<div class="item materials">
					<materials
						:item="item"
						:value="data.materials"
						@input="updateMaterials"
						:sort="true"
					/>
				</div>
			</div>
		</div>

		<addons v-bind="data" />
	</div>
</template>

<script>
import {Button, InputNumber, Checkbox, Tooltip, Input} from "element-ui";
import stockItem from "@/components/stock-item";
import materials from "@/components/materials";
import {debounce, cloneDeep} from "lodash";
import addons from "./addons";

export default {
	components: {
		Button,
		InputNumber,
		Checkbox,
		Tooltip,
		stockItem,
		materials,
		addons,
		Input
	},
	props: {
		item: {type: Object, required: true}
	},
	data() {
		return {
			payload: {},
			data: {}
		};
	},
	methods: {
		view() {
			this.$router.push(`/t/stock-items/${this.data.stock_item.id}`);
		},

		async updateStockItem({id, material_id}) {
			const materials = [{id: material_id}].filter((x) => x.id !== null);
			this.update({}, {materials, stock_item_id: id});
		},

		async updateMaterials(materials) {
			await this.update({}, {materials});
		},

		async updateCoverId({id}) {
			this.update({}, {cover_id: id});
		},

		update(data, payload) {
			this.payload = {...this.payload, ...(payload || data)};
			this.data = {...this.data, ...data};
			this.updateDeb();
		},

		updateDeb: debounce(async function() {
			await this.$store.dispatch("pricelistGroup/updateLine", {
				data: this.payload,
				id: this.data.id
			});

			this.payload = {};
		}, 700),

		async addAddon() {
			await this.$store.dispatch("pricelistGroup/addAddon", {
				stockItem: this.data.stock_item,
				lineId: this.data.id
			});
		},

		async remove() {
			this.$store.dispatch("pricelistGroup/removeLine", {id: this.data.id});
		}
	},
	watch: {
		item: {
			deep: true,
			immediate: true,
			handler(item) {
				this.data = cloneDeep(item);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.line {
	padding: 16px;
	background-color: #f5f7fa;
	border: 1px solid #e2e4e8;
	border-radius: 2px;
	margin: 0.5em 0;

	.line-item {
		display: flex;
		flex-direction: column;

		.top,
		.bottom {
			display: flex;
			align-items: center;
		}

		.top {
			margin-bottom: 0.5em;
		}

		.bottom {
			margin-left: 2.25em;
			display: flex;
			flex-direction: column;
		}

		.drag-line {
			padding: 1em;
			margin: -1em 0;
			margin-left: -1em;
		}

		.item {
			margin-right: 0.5em;

			&.skulist {
				flex-shrink: 0;
				width: 150px;
			}

			&.stockitem {
				width: 100%;
				max-width: 420px;
				flex-shrink: 0;
				zoom: 0.9;
			}

			&.materials {
				width: 100%;
				zoom: 0.9;
			}

			&.quantity {
				z-index: 0;
				flex-shrink: 0;
				width: 5em;

				> div {
					width: 100%;
				}
			}

			&.coverId {
				width: 100%;
				max-width: 100%;
				zoom: 0.9;
				margin-bottom: 0.5em;
			}

			&.addon {
			}

			&.break {
				z-index: 0;
				::v-deep {
					.el-checkbox__inner {
						border-color: #a7a7a7;
						background-color: transparentize(#409eff, 0.7);
					}

					.el-checkbox__input.is-checked .el-checkbox__inner {
						border-color: #409eff;
						background-color: #409eff;
					}
				}
			}

			&.show-materials {
				z-index: 0;

				::v-deep {
					.el-checkbox__inner {
						border-color: #a7a7a7;
						background-color: transparentize(#67c23a, 0.7);
					}

					.el-checkbox__input.is-checked .el-checkbox__inner {
						border-color: #67c23a;
						background-color: #67c23a;
					}
				}
			}

			&.remove {
				margin-left: 0em;
				color: #c0ccda;
			}
		}
	}
}
</style>
