<template>
	<div class="language">
		<Select :value="value" @input="select">
			<Option
				v-for="item in options"
				:key="item.code"
				:label="item.name"
				:value="item.code"
			/>
		</Select>
	</div>
</template>

<script>
import {Select, Option} from "element-ui";
import {get} from "lodash";

export default {
	components: {Select, Option},
	computed: {
		value: (t) => get(t.$route.query, "modifiers.language_code", "en"),
		options: (t) => t.$store.getters["pricelist/languages"]
	},
	methods: {
		select(language_code) {
			this.$router.replace({
				...this.$route,
				query: {modifiers: {language_code}}
			});

			location.reload();
		}
	}
};
</script>
