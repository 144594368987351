<template>
	<layout v-if="data" class="pricelist">
		<template slot="header">
			<div class="title" v-text="data.name" />
		</template>

		<div class="main" slot="main">
			<div class="box-header">
				<div class="title">Pricelist groups</div>
				<sort v-bind="data" />
				<create />
			</div>

			<groups v-bind="data" />
		</div>

		<div class="sidebar" slot="sidebar">
			<vExport v-bind="data" />
		</div>
	</layout>
</template>

<script>
import layout from "@/components/layout";
import vExport from "./export";
import create from "./create";
import groups from "./groups";
import sort from "./sort";

let scrollY;

export default {
	components: {layout, vExport, create, groups, sort},
	computed: {
		data: (t) => t.$store.getters["pricelist/data"]
	},
	methods: {
		scroll() {
			scrollY = window.scrollY;
		}
	},
	created() {
		this.$store.dispatch("pricelist/data", {id: this.$route.params.id});
		this.$store.dispatch("pricelist/languages");
	},
	beforeDestroy() {
		this.$store.commit("pricelist/data", null);
		window.removeEventListener("scroll", this.scroll);
	},
	watch: {
		data(data) {
			if (data) {
				window.addEventListener("scroll", this.scroll);
				this.$nextTick(() => {
					window.scrollTo(0, scrollY);
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.pricelist {
	.main {
		.header {
			display: flex;
			align-items: center;
		}
	}
}
</style>
