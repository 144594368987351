<template>
	<Select
		v-bind="{value, request}"
		:allowEmpty="true"
		label="combined_sku_name"
		placeholder="parts"
		track-by="id"
		:multi="true"
		@input="update"
	/>
</template>

<script>
import {Select} from "./form";

export default {
	components: {Select},
	props: {
		value: {type: Array, required: false}
	},
	data() {
		return {
			request: (q = "") =>
				this.$axios.get("stock-items", {
					params: {sort: "nav_id,name", filter: {search: q}}
				})
		};
	},
	methods: {
		update(items) {
			this.$emit("input", items);
		}
	}
};
</script>
