<template>
	<div class="addons" v-if="addons.length">
		<div class="header">
			<div class="title">
				<div class="text">Addons</div>
				<div class="count" v-text="addons.length" />
			</div>
		</div>
		<draggable
			v-model="items"
			:options="{handle: '.drag-addon'}"
			@end="reorder"
		>
			<item
				v-for="(item, index) in items"
				:key="item.id"
				v-bind="{index, item}"
			/>
		</draggable>
	</div>
</template>

<script>
import draggable from "vuedraggable";
import item from "./item.vue";

export default {
	components: {draggable, item},
	props: {
		stock_item: {type: Object, required: true},
		addons: {type: Array, required: true}
	},
	data() {
		return {
			items: []
		};
	},
	computed: {
		ids: (t) => t.items.map(({id}) => id)
	},
	methods: {
		async reorder() {
			await this.$store.dispatch("pricelistGroup/reorderAddons", {
				ids: this.ids
			});
		}
	},
	created() {
		this.$watch("addons", (addons) => (this.items = addons), {immediate: true});
	}
};
</script>

<style lang="scss" scoped>
.addons {
	margin-top: 1em;
	padding-top: 1em;
	border-top: 1px solid #e2e4e8;

	.header {
		display: flex;
		margin-left: 2.7em;

		.title {
			display: flex;
			// align-items: center;

			.text {
				font-size: 12px;
				font-weight: 500;
				color: #4c596f;
				margin-right: 6px;
			}

			.count {
				font-size: 12px;
				color: #c0ccda;
			}
		}
	}
}
</style>
