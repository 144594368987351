import Semaphore from "semaphore-async-await";
const s = new Semaphore(1);

export default ({$axios, $router}) => {
	const params = {
		get modifier() {
			return $router.currentRoute.query.modifiers;
		}
	};

	return {
		namespaced: true,

		state: {
			languages: null,
			data: null,

			route: {
				name: "pricelist",
				params: {}
			}
		},

		mutations: {
			data(state, data) {
				state.data = data;
			},
			groups(state, data) {
				state.data.groups = data;
			},
			languages(state, data) {
				state.languages = data;
			}
		},

		actions: {
			async languages({commit, state}) {
				if (!state.languages) {
					const {data} = await $axios.get("languages");
					commit("languages", data.data);
				}
			},
			async data({commit}, {id}) {
				const {data} = await $axios.get(`price-lists/${id}`, {params});
				commit("data", data.data);
			},

			async update({getters}, {data}) {
				await $axios.put(`price-lists/${getters.data.id}`, {data}, {params});
			},

			async addGroup({getters, commit}, {form}) {
				const payload = {
					data: {...form, price_list_id: getters.data.id}
				};

				const {data} = await $axios.post("price-list-groups", payload, {
					customErr: true,
					params
				});

				commit("groups", [...getters.data.groups, data.data]);
			},

			async removeGroup({getters, commit}, {id}) {
				await $axios.delete(`price-list-groups/${id}`, {params});
				commit("groups", getters.data.groups.filter((x) => x.id !== id));
			},

			async reorderGroups(ctx, {ids}) {
				await s.acquire();
				await $axios.post("price-list-groups/reorder", {ids}, {params});
				s.release();
			}
		},

		getters: {
			data(state) {
				return state.data;
			},
			languages(state) {
				return state.languages || [];
			}
		}
	};
};
