var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vselect',_vm._b({attrs:{"internal-search":!_vm.request,"allow-empty":_vm.allowEmpty,"searchable":true,"optionHeight":36,"tagPlaceholder":"","selectedLabel":"","deselectLabel":"","closeOnSelect":!_vm.multi,"taggable":_vm.multi,"multiple":_vm.multi,"selectLabel":""},on:{"input":_vm.update,"open":_vm.open,"search-change":_vm.search}},'vselect',{
		label: _vm.label,
		trackBy: _vm.trackBy,
		loading: _vm.loading,
		placeholder: _vm.placeholder,
		options: _vm.optionsC,
		value: _vm.valueC
	},false),[_c('template',{slot:"noResult"},[_vm._v(" No result ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }