<template>
	<div class="settings">
		<div class="header">
			<div class="title">
				Group settings
			</div>
		</div>

		<Form size="medium">
			<FormItem name="name" label="Group name (Series)">
				<Input v-model="form.name" @input="update" />
			</FormItem>
			<FormItem name="material" label="Material">
				<Input v-model="form.material" @input="update" />
			</FormItem>
			<FormItem name="designer" label="Designer">
				<Input v-model="form.designer" @input="update" />
			</FormItem>
			<FormItem name="page_break_before">
				<Checkbox v-model="form.page_break_before" @input="update">
					Page break before
				</Checkbox>
			</FormItem>

			<Button class="back" type="primary" @click="back">
				Back to product list
			</Button>
		</Form>
	</div>
</template>

<script>
import {Form, FormItem} from "@/components/form";
import {Input, Checkbox, Button} from "element-ui";
import {debounce, pick} from "lodash";

export default {
	components: {Form, FormItem, Input, Checkbox, Button},
	props: {
		id: {type: Number, required: true},
		name: {type: String, required: true},
		material: {type: String, required: false},
		designer: {type: String, required: false},
		page_break_before: {type: Boolean, required: true}
	},
	data() {
		return {
			form: pick(this, [
				"id",
				"name",
				"material",
				"designer",
				"page_break_before"
			])
		};
	},
	methods: {
		update: debounce(async function() {
			await this.$store.dispatch("pricelistGroup/update", {data: this.form});
		}, 500),

		back() {
			this.$router.push({name: "pricelist", params: this.$route.params});
		}
	}
};
</script>

<style lang="scss" scoped>
.settings {
	.back {
		width: 100%;
	}
}
</style>
