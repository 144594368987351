<template>
	<div class="lines">
		<draggable v-model="items" :options="{handle: '.drag-line'}" @end="reorder">
			<item v-for="item in items" :key="item.id" v-bind="{item}" />
		</draggable>
	</div>
</template>

<script>
import draggable from "vuedraggable";
import item from "./line";

export default {
	components: {draggable, item},
	props: {
		id: {type: Number, required: true},
		lines: {type: Array, required: true}
	},
	data() {
		return {
			items: []
		};
	},
	computed: {
		ids: (t) => t.items.map(({id}) => id)
	},
	methods: {
		async reorder() {
			await this.$store.dispatch("pricelistGroup/reorderLines", {
				ids: this.ids
			});
		}
	},
	created() {
		this.$watch("lines", () => (this.items = this.lines), {immediate: true});
	}
};
</script>

<style lang="scss" scoped>
.lines {
}
</style>
