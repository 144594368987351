<template>
	<div class="items">
		<draggable v-model="items" :options="{handle: '.drag'}" @end="reorder">
			<item v-for="item in items" :key="item.id" v-bind="item" />
		</draggable>
	</div>
</template>

<script>
import draggable from "vuedraggable";
import item from "./item.vue";

export default {
	components: {draggable, item},
	props: {
		groups: {type: Array, required: true}
	},
	data() {
		return {
			items: []
		};
	},
	computed: {
		ids: (t) => t.items.map(({id}) => id)
	},
	methods: {
		reorder() {
			this.$store.dispatch("pricelist/reorderGroups", {ids: this.ids});
		}
	},
	created() {
		this.$watch("groups", () => (this.items = this.groups), {immediate: true});
	}
};
</script>

<style lang="scss" scoped>
.items {
}
</style>
