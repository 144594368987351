<template>
	<div class="materials">
		<div class="button" v-if="sort">
			<Button size="medium" icon="el-icon-d-caret" @click="modal = true" />
		</div>

		<Select
			v-bind="{value, request}"
			placeholder="Materials"
			:allowEmpty="true"
			label="title"
			track-by="id"
			:multi="true"
			@input="update"
		/>

		<Dialog title="Sort" width="540px" :visible.sync="modal">
			<reorder
				:key="!modal && reorderKey"
				v-bind="reorderProps"
				@event="reorder"
				v-if="item"
			/>
		</Dialog>
	</div>
</template>

<script>
import {Button, Dialog} from "element-ui";
import reorder from "./materials-drag";
import {Select} from "./form";

export default {
	components: {Button, Dialog, Select, reorder},
	props: {
		addonIndex: {type: Number, required: false},
		addon: {type: Boolean, required: false},
		sort: {type: Boolean, required: false},
		value: {type: Array, required: false},
		item: {type: Object, required: false}
	},
	data() {
		return {
			request: (q = "") =>
				this.$axios.get("materials", {
					params: {sort: "variant_value", filter: {title: q}}
				}),

			modal: false
		};
	},
	computed: {
		reorderKey: (t) => t.item.materials.map((x) => x.id).join("-"),
		reorderProps() {
			const addonIndex = this.addonIndex;
			const addon = this.addon;
			const item = this.item;

			return {
				_prop: "items",
				_options: {
					value: "id",
					get key() {
						if (addon) {
							return `data.addons[${addonIndex}].materials`;
						} else {
							return "data.materials";
						}
					},
					endpoint: {
						get url() {
							if (addon) {
								return `price-list-group-lines/${
									item.price_list_group_line_id
								}`;
							} else {
								return `price-list-group-lines/${item.id}`;
							}
						}
					}
				},
				_columns: [
					{
						key: "name",
						label: "Name"
					}
				]
			};
		}
	},
	methods: {
		reorder(e) {
			const items = e.actions.update.data.items;
			const data = items.map((id) => this.value.find((x) => x.id === id));
			this.update(data);
		},
		update(items) {
			this.$emit("input", items);
		}
	}
};
</script>

<style lang="scss" scoped>
.materials {
	display: flex;
	align-items: center;
	font-size: 18px;

	.button {
		margin-right: 0.5em;
	}
}
</style>
