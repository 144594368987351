<template>
	<div class="sort">
		<Button class="sort" icon="el-icon-refresh" @click="sort">A-Z</Button>
	</div>
</template>

<script>
import {sortBy} from "lodash";
import {Button} from "element-ui";

export default {
	components: {Button},
	props: {
		groups: {type: Array, required: true}
	},
	computed: {
		groupsSorted: (t) => sortBy(t.groups, "name"),
		idsSorted: (t) => t.groupsSorted.map((x) => x.id)
	},
	methods: {
		sort() {
			this.$store.dispatch("pricelist/reorderGroups", {ids: this.idsSorted});
			this.$store.commit("pricelist/groups", this.groupsSorted);
		}
	}
};
</script>

<style lang="scss" scoped>
.sort {
	margin-left: auto;
	margin-right: 0.5em;
}
</style>
