import group from "./pages/pricelist-group";
import pricelist from "./pages/pricelist";
import "@signifly/travy/dist/lib.css";
import {app} from "@signifly/travy";
import store from "./store";

const vm = app({
	api: process.env.API,
	routes: [
		{
			path: "/c/price-lists/:id",
			name: "pricelist",
			component: pricelist,
			meta: {layout: "main", title: "Pricelist Groups", auth: {roles: "all"}}
		},
		{
			path: "/c/price-lists/:id/:groupId",
			name: "pricelist-group",
			component: group,
			meta: {layout: "main", title: "Pricelist Group", auth: {roles: "all"}}
		}
	]
});

store(vm);
