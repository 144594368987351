<template>
	<div class="create">
		<Button icon="el-icon-plus" @click="active = true">Add new group</Button>

		<Dialog :visible.sync="active" width="540px" title="Create new group">
			<Form :model="form">
				<FormItem name="name" label="Name" v-bind="error">
					<Input v-model="form.name" />
				</FormItem>
				<FormItem name="material" label="Material">
					<Input v-model="form.material" />
				</FormItem>
				<FormItem name="designer" label="Designer">
					<Input v-model="form.designer" />
				</FormItem>
				<FormItem name="page_break_before">
					<Checkbox v-model="form.page_break_before">
						Page break before
					</Checkbox>
				</FormItem>
			</Form>

			<span slot="footer" class="footer">
				<Button type="info" plain @click="active = false">Cancel</Button>
				<Button type="primary" @click="submit" v-bind="{loading}"
					>Confirm</Button
				>
			</span>
		</Dialog>
	</div>
</template>

<script>
import {Button, Dialog, Input, Checkbox} from "element-ui";
import {Form, FormItem} from "@/components/form";

const newForm = () => ({
	name: "",
	material: "",
	designer: "",
	page_break_before: false
});

export default {
	components: {Button, Dialog, Form, FormItem, Input, Checkbox},
	data() {
		return {
			error: {},
			active: false,
			loading: false,
			form: newForm()
		};
	},
	methods: {
		async submit() {
			this.loading = true;

			try {
				this.error = {};
				await this.$store.dispatch("pricelist/addGroup", {form: this.form});
				this.active = false;
				this.form = newForm();
			} catch (err) {
				this.error = err;
			}

			this.loading = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.create {
}
</style>
