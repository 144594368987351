<template>
	<div class="item" :style="{width: widthC}">
		<FormItem v-bind="{error}">
			<slot name="label">
				<div class="label">{{ label }}</div>
			</slot>
			<slot />
		</FormItem>
	</div>
</template>

<script>
import {get} from "lodash";
import {FormItem} from "element-ui";

export default {
	components: {FormItem},
	props: {
		errors: {type: Object, required: false},
		label: {type: String, required: false},
		width: {type: String, required: false},
		name: {type: String, required: false}
	},
	computed: {
		error: (t) => get(t.errors, t.name, [])[0],
		widthC: (t) => (t.width ? `calc(${t.width} - 1em)` : "100%")
	}
};
</script>

<style lang="scss" scoped>
.item {
	width: 100%;
	::v-deep {
		.el-select {
			width: 100%;
		}
	}
}
</style>
