<template>
	<div class="box">
		<slot />
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
	padding: 28px 30px;
	background-color: $white1;
	border: 1px solid $blue2;
	border-radius: 4px;

	::v-deep {
		.box-header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 1em;
		}
	}
}
</style>
