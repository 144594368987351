<template>
	<div class="export">
		<div class="header">
			<div class="title">
				Export pricelist
			</div>
		</div>

		<Form size="medium" v-if="options">
			<div class="presets">
				<FormItem label="Preset">
					<Select
						@input="selectPreset"
						:options="presets"
						:key="resetPreset"
						track-by="name"
						label="name"
					/>
				</FormItem>
			</div>

			<FormItem name="name" label="Pricelist name">
				<Input v-model="form.name" @input="update" />
			</FormItem>

			<FormItem name="currency_id" label="Currency" width="50%">
				<Select
					:value="options.currencies.find((x) => form.currency_id === x.id)"
					:options="options.currencies"
					track-by="id"
					label="name"
					@input="input({key: 'currency_id', $event})"
				/>
			</FormItem>

			<FormItem name="language_id" label="Language" width="50%">
				<Select
					:value="options.languages.find((x) => form.language_id === x.id)"
					:options="options.languages"
					track-by="id"
					label="name"
					@input="input({key: 'language_id', $event})"
				/>
			</FormItem>

			<FormItem name="date" label="Date of prices" width="50%">
				<DatePicker
					v-model="form.date_of_prices"
					value-format="yyyy-MM-dd"
					format="dd-MM-yyyy"
					:editable="false"
					:clearable="false"
					:picker-options="{firstDayOfWeek: 1}"
					@change="update"
				/>
			</FormItem>

			<FormItem name="measurement_unit" label="Measurements" width="50%">
				<Select
					:value="options.units.find((x) => form.measurement_unit === x.id)"
					:options="options.units"
					track-by="id"
					label="name"
					@input="input({key: 'measurement_unit', $event})"
				/>
			</FormItem>

			<FormItem name="volume_enabled" label="Volume">
				<sw v-model="form.volume_enabled" @input="update" />
			</FormItem>

			<Button class="submit" type="primary" @click="submit">
				Export as pdf
			</Button>

			<a ref="download" :href="export_url" target="_blank" />
		</Form>
	</div>
</template>

<script>
import {Form, FormItem, Select} from "@/components/form";
import {Input, DatePicker, Button, Switch as sw} from "element-ui";
import {debounce, pick} from "lodash";

export default {
	components: {Form, FormItem, Select, Input, DatePicker, Button, sw},
	props: {
		name: {type: String, required: true},
		export_url: {type: String, required: true},
		currency_id: {type: Number, required: true},
		language_id: {type: Number, required: true},
		volume_enabled: {type: Boolean, required: true},
		date_of_prices: {type: String, required: false},
		measurement_unit: {type: String, required: true}
	},
	data() {
		return {
			options: null,
			resetPreset: 0,

			presets: [
				{
					name: "DKKBRT",
					currency_id: 4,
					language_id: 2,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "DKKEXMOMS",
					currency_id: 3,
					language_id: 2,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "EURBRT 2",
					currency_id: 5,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "EURBRT_ENG",
					currency_id: 2,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "EURBRT_DEU",
					currency_id: 2,
					language_id: 3,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "EUREXMOMS_ENG",
					currency_id: 6,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "EUREXMOMS_DEU",
					currency_id: 6,
					language_id: 3,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "FINEXMOMS",
					currency_id: 7,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "FR/ITEXMOMS",
					currency_id: 9,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "GBPBRT",
					currency_id: 10,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "GBPEXMOMS",
					currency_id: 11,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "NOKBRT",
					currency_id: 12,
					language_id: 5,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "NOKEXMOMS",
					currency_id: 13,
					language_id: 5,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "SEKBRT",
					currency_id: 16,
					language_id: 4,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "SEKEXMOMS",
					currency_id: 17,
					language_id: 4,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "USAMSRP",
					currency_id: 1,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "inches"
				},
				{
					name: "USDMSRP",
					currency_id: 1,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "FOB_USD_JAKARTA",
					currency_id: 8,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "cm"
				},
				{
					name: "FOB_USA_CANADA",
					currency_id: 20,
					language_id: 1,
					date_of_prices: "2020-01-01",
					measurement_unit: "inches"
				},
				{
					name: "CADMSRP",
					currency_id: 72,
					language_id: 1,
					date_of_prices: "2021-01-01",
					measurement_unit: "inches"
				},
				{
					name: "CHFBRT",
					currency_id: 73,
					language_id: 3,
					date_of_prices: "2021-01-01",
					measurement_unit: "cm"
				}
			],

			form: pick(this, [
				"name",
				"currency_id",
				"language_id",
				"volume_enabled",
				"date_of_prices",
				"measurement_unit"
			])
		};
	},
	computed: {
		tt() {
			return this.options.currencies.find(
				(x) => this.form.currency_id === x.id
			);
		}
	},
	methods: {
		selectPreset(preset) {
			this.form = {
				...preset,
				name: this.form.name
			};
			this.update({preset: true});
		},
		async getOptions() {
			const data = {
				currencies: "currencies?sort=iso_code,nav_code&count=150",
				languages: "languages?sort=name&count=150",
				units: "measurement-units?count=150"
			};

			const options = await Promise.all(
				Object.entries(data).map(async ([key, val]) => ({
					key,
					val: (await this.$axios.get(val)).data.data
				}))
			);

			this.options = options.reduce(
				(obj, {key, val}) => ({...obj, [key]: val}),
				{}
			);
		},

		input({key, $event}) {
			this.form[key] = $event.id;
			this.update();
		},

		update: debounce(async function({preset} = {}) {
			if (!preset) this.resetPreset++;
			await this.$store.dispatch("pricelist/update", {data: this.form});
		}, 500),

		submit() {
			this.$refs.download.click();
		}
	},
	created() {
		this.getOptions();
	}
};
</script>

<style lang="scss" scoped>
.export {
	.header {
		.title {
			margin-bottom: 1em;
		}
	}

	.submit {
		width: 100%;
	}
}
</style>
