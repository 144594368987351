<template>
	<vselect
		v-bind="{
			label,
			trackBy,
			loading,
			placeholder,
			options: optionsC,
			value: valueC
		}"
		:internal-search="!request"
		:allow-empty="allowEmpty"
		:searchable="true"
		:optionHeight="36"
		tagPlaceholder=""
		selectedLabel=""
		deselectLabel=""
		:closeOnSelect="!multi"
		:taggable="multi"
		:multiple="multi"
		selectLabel=""
		@input="update"
		@open="open"
		@search-change="search"
	>
		<template slot="noResult">
			No result
		</template>
	</vselect>
</template>

<script>
import {debounce} from "lodash";
import vselect from "vue-multiselect";

export default {
	components: {vselect},
	props: {
		placeholder: {type: String, required: false},
		request: {type: Function, required: false},
		value: {type: [Object, Array], required: false},
		trackBy: {type: String, required: true},
		options: {type: Array, required: false},
		multi: {type: Boolean, required: false},
		label: {type: String, required: true},
		allowEmpty: {type: Boolean, default: false}
	},
	data() {
		return {
			opened: false,
			loading: false,
			valueC: this.value,
			optionsC: this.options || []
		};
	},
	methods: {
		open() {
			if (!this.opened) {
				if (!this.request) return;
				this.opened = true;
				this.getOptions();
			}
		},

		async search(search) {
			if (!this.request) return;

			this.loading = true;
			this.getOptionsDebounced(search);
		},

		async getOptions(search) {
			this.loading = true;
			const {
				data: {data}
			} = await this.request(search);
			this.optionsC = data;
			this.loading = false;
		},

		update(item) {
			this.valueC = item;
			this.$emit("input", item || {id: null});
		}
	},
	watch: {
		value(value) {
			this.valueC = value;
		}
	},
	created() {
		this.getOptionsDebounced = debounce(this.getOptions, 300);
	}
};
</script>

<style lang="scss">
@import "~vue-multiselect/dist/vue-multiselect.min.css";

$height: 36px;

.multiselect {
	line-height: initial;
	min-height: $height;

	* {
		font-size: 14px;
	}

	&__tags {
		width: 100%;
		min-height: $height;
		border-radius: 4px;
		text-overflow: ellipsis;
	}

	&__tag {
		background: #409eff;

		span {
			font-size: 12px;
		}

		&-icon {
			&:hover,
			&:focus {
				background: #409eff;
			}
		}
	}

	&__single {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__option {
		&--selected,
		&--highlight {
			background-color: #f2f3f4 !important;
			color: black !important;
		}
	}

	&__select {
		height: $height;
	}

	&--active {
		.multiselect__select {
			z-index: 1;
		}
	}
}
</style>
