import Semaphore from "semaphore-async-await";
const s = new Semaphore(1);

export default ({$axios, $router}) => {
	const params = {
		get modifier() {
			return $router.currentRoute.query.modifiers;
		}
	};

	return {
		namespaced: true,

		state: {
			data: null
		},

		mutations: {
			data(state, data) {
				state.data = data;
			}
		},

		actions: {
			async data({getters, commit}, {id} = {}) {
				id = id || getters.data.id;

				const {data} = await $axios.get(`price-list-groups/${id}`, {
					params: {...params, count: 1000}
				});

				commit("data", data.data);
			},

			async update(ctx, {data}) {
				await $axios.put(`price-list-groups/${data.id}`, {data}, {params});
			},

			async addLine({getters, dispatch}, {form}) {
				const data = {...form, price_list_group_id: getters.data.id};

				await $axios.post(
					"price-list-group-lines",
					{data},
					{params, customErr: true}
				);

				await dispatch("data");
			},

			async updateLine({getters}, {id, data}) {
				await s.acquire();

				if (data.materials && data.materials.length) {
					await $axios.put(
						`price-list-group-lines/${id}`,
						{data: {materials: []}},
						{params}
					);
				}

				const {
					data: {data: line}
				} = await $axios.put(`price-list-group-lines/${id}`, {data}, {params});

				const stateLine = getters.data.lines.find((x) => x.id === line.id);

				Object.assign(stateLine, {
					...data,
					cover: line.cover,
					materials: line.materials,
					stock_item: line.stock_item
				});

				s.release();
			},

			async reorderLines(ctx, {ids}) {
				await s.acquire();
				await $axios.post("price-list-group-lines/reorder", {ids});
				await s.release();
			},

			async removeLine({getters, commit}, {id}) {
				await $axios.delete(`price-list-group-lines/${id}`, {params});

				commit("data", {
					...getters.data,
					lines: getters.data.lines.filter((x) => x.id !== id)
				});
			},

			async addAddon({dispatch}, {lineId, stockItem}) {
				await s.acquire();

				await $axios.post(
					"price-list-group-line-addons",
					{
						data: {
							price_list_group_line_id: lineId,
							stock_item_id: stockItem.id,
							quantity: 1,
							materials:
								stockItem.material_id === null
									? undefined
									: [{id: stockItem.material_id}]
						}
					},
					{params}
				);

				await dispatch("data");
				s.release();
			},

			async updateAddon({getters}, {id, data}) {
				if (data.materials && data.materials.length) {
					await $axios.put(
						`price-list-group-line-addons/${id}`,
						{data: {materials: []}},
						{params}
					);
				}

				const {
					data: {data: addon}
				} = await $axios.put(
					`price-list-group-line-addons/${id}`,
					{data},
					{params}
				);

				const stateAddon = getters.data.lines
					.find((x) => x.id === addon.price_list_group_line_id)
					.addons.find((x) => x.id === addon.id);

				Object.assign(stateAddon, addon);
			},

			async removeAddon({dispatch}, {id}) {
				await s.acquire();
				await $axios.delete(`price-list-group-line-addons/${id}`, {params});
				await dispatch("data");
				s.release();
			},

			async reorderAddons(ctx, {ids}) {
				await s.acquire();
				await $axios.post(
					"price-list-group-line-addons/reorder",
					{ids},
					{params}
				);
				s.release();
			}
		},

		getters: {
			data(state) {
				return state.data;
			}
		}
	};
};
