<template>
	<div class="layout">
		<div class="header">
			<slot name="header" />
			<language />
		</div>
		<Row :gutter="20">
			<Col :span="16">
				<box>
					<slot name="main" v-text="main" />
				</box>
			</Col>
			<Col :span="8">
				<box>
					<slot name="sidebar" v-text="sidebar" />
				</box>
			</Col>
		</Row>
	</div>
</template>

<script>
import language from "@/components/language";
import {Row, Col} from "element-ui";
import box from "./box.vue";

export default {
	components: {language, Row, Col, box}
};
</script>

<style lang="scss" scoped>
.layout {
	margin-top: 4em;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		justify-content: space-between;

		.title {
			font-size: 20px;
			display: flex;
			align-items: center;
		}
	}
}
</style>
