<template>
	<Form size="medium">
		<slot />
	</Form>
</template>

<script>
import {Form} from "element-ui";

export default {
	components: {Form},
	props: {}
};
</script>
