<template>
	<div class="group">
		<div class="break" v-if="page_break_before">
			<div class="line" />
			<div class="text">Page break</div>
		</div>

		<div class="wrap">
			<div class="icon drag">
				<i class="el-icon-rank"></i>
			</div>

			<div class="info">
				<div class="name" v-text="name" />
				<div class="designer">{{ designer }}</div>
			</div>

			<div class="material" v-if="material">
				<div class="title">Material</div>
				<div class="name">{{ material }}</div>
			</div>

			<div class="extra">
				<div class="products">
					<div class="title">Products:</div>
					<div class="count">{{ lines.length }}</div>

					<Tooltip placement="right" :disabled="!lines.length">
						<div
							class="tooltip"
							slot="content"
							:style="{maxHeight: '40em', overflow: 'auto'}"
						>
							<div
								class="item"
								v-for="line in lines"
								:key="line.id"
								:style="{margin: '0.1em 0'}"
							>
								<div
									class="title"
									v-text="line.stock_item.combined_sku_name"
									:style="{fontWeight: 500}"
								/>

								<div
									class="children"
									v-if="line.addons.length"
									:style="{
										marginBottom: '0.4em',
										marginTop: '0.25em',
										marginLeft: '1em'
									}"
								>
									<div v-for="addon in line.addons" :key="addon.id">
										↳ {{ addon.stock_item.combined_sku_name }}
									</div>
								</div>
							</div>
						</div>
						<i class="el-icon-info" />
					</Tooltip>
				</div>

				<div class="pagebreaks" v-if="pagebreaks">
					<i class="el-icon-caret-right" />
					Contains page breaks
				</div>
			</div>

			<div class="remove">
				<a class="link" @click="remove">
					<i class="el-icon-delete" />
				</a>
			</div>

			<div class="edit">
				<a class="link" @click="edit">
					<i class="el-icon-edit" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import {Tooltip} from "element-ui";

export default {
	components: {Tooltip},
	props: {
		id: {type: Number, required: true},
		name: {type: String, required: true},
		lines: {type: Array, required: true},
		material: {type: String, required: false},
		designer: {type: String, required: false},
		page_break_before: {type: Boolean, required: true}
	},
	computed: {
		pagebreaks: (t) => t.lines.some((x) => x.page_break_before)
	},
	methods: {
		async remove() {
			await this.$store.dispatch("pricelist/removeGroup", {id: this.id});
		},
		edit() {
			this.$router.push({
				name: "pricelist-group",
				params: {id: this.$route.params.id, groupId: this.id}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.group {
	.wrap {
		background-color: #f5f7fa;
		border: 1px solid #e2e4e8;
		border-radius: 2px;
		padding: 0 26px;
		display: flex;
		align-items: center;
		margin: 0.5em 0;
		height: 80px;

		.drag {
			padding: 1em;
			margin-left: -1em;
		}

		> .info {
			width: 35%;
			flex-shrink: 0;

			.name {
				font-weight: bold;
				color: #5e6d82;
				margin-bottom: 0.2em;
			}
			.designer {
				font-size: 13px;
				font-style: italic;
				color: #8492a6;
			}
		}

		.material {
			margin-left: 0;
			margin-right: 1em;
			max-width: 240px;

			.title {
				color: $dark1;
				font-size: 13px;
				font-weight: 600;
				margin-right: 0.5em;
				margin-bottom: 0.25em;
			}

			.name {
				font-size: 13px;
				color: $dark1;
			}
		}

		.extra {
			width: 12em;
			margin-right: 2em;
			margin-left: auto;
			display: flex;
			flex-direction: column;
			font-size: 13px;
			color: $dark1;

			.products {
				display: inline-flex;
				align-items: center;
				color: $dark1;

				.title {
					font-weight: 600;
					margin-right: 0.5em;
				}

				.count {
					margin-right: 0.4em;
				}

				i {
					font-size: 12px;
					margin-top: 0.1em;
				}
			}

			.pagebreaks {
				color: #8492a6;
				font-size: 0.9em;
				margin-top: 0.2em;
				margin-left: -0.3em;
			}
		}

		.remove {
			margin-right: 1.5em;
			color: #c0ccda;
		}

		.edit {
			.link {
				background-color: #5e6d82;
				color: $white1;
				$s: 36px;
				width: $s;
				height: $s;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
			}
		}
	}

	.break {
		position: relative;
		padding: 0.3em 0;

		.line {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			background-color: #dfe5ec;
			height: 2px;
			width: 100%;
		}

		.text {
			position: relative;
			background-color: #fff;
			font-size: 13px;
			font-weight: 500;
			color: #c0ccda;
			width: 7em;
			margin: 0 auto;
			text-align: center;
			margin-top: -0.175em;
		}
	}
}
</style>
