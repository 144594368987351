<template>
	<div class="create">
		<Button icon="el-icon-plus" @click="active = true">
			Add new productline
		</Button>

		<Dialog :visible.sync="active" width="540px" title="Add new productline">
			<Form :model="form" :key="active">
				<FormItem name="quantity" label="Quantity" v-bind="error">
					<Input
						size="medium"
						:controls="false"
						v-model.number="form.quantity"
					/>
				</FormItem>

				<FormItem name="stock_item_id" label="Stock item" v-bind="error">
					<stockItem v-model="form.stockItem" @input="updateStockItem" />
				</FormItem>

				<FormItem name="materials" label="Materials" v-bind="error">
					<materials v-model="form.materials" />
				</FormItem>
			</Form>

			<span slot="footer" class="footer">
				<Button type="info" plain @click="active = false">Cancel</Button>
				<Button type="primary" @click="submit" v-bind="{loading}">
					Confirm
				</Button>
			</span>
		</Dialog>
	</div>
</template>

<script>
import {Button, Dialog, Form, Input} from "element-ui";
import stockItem from "@/components/stock-item.vue";
import materials from "@/components/materials.vue";
import {FormItem} from "@/components/form";

const newForm = () => ({
	quantity: null,
	stockItem: {},
	materials: []
});

export default {
	components: {Button, Dialog, Form, FormItem, Input, stockItem, materials},
	data() {
		return {
			error: {},
			active: false,
			loading: false,
			form: newForm()
		};
	},
	computed: {
		payload: ({form}) => ({
			quantity: form.quantity,
			materials: form.materials,
			stock_item_id: form.stockItem.id
		})
	},
	methods: {
		async updateStockItem({material_id}) {
			const materials = await (async () => {
				if (material_id === null) return [];

				const {
					data: {data: material}
				} = await this.$axios.get(`materials/${material_id}`);

				return [material];
			})();

			this.form.materials = materials;
		},

		async submit() {
			this.loading = true;

			try {
				this.error = {};
				await this.$store.dispatch("pricelistGroup/addLine", {
					form: this.payload
				});
				this.form = newForm();
				this.active = false;
			} catch (err) {
				this.error = err;
			}

			this.loading = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.create {
}
</style>
