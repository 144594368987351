<template>
	<Select
		v-bind="{value, request, placeholder, allowEmpty}"
		label="combined_sku_name"
		track-by="id"
		@input="update"
	/>
</template>

<script>
import {Select} from "./form";

export default {
	components: {Select},
	props: {
		placeholder: {type: String, required: false},
		allowEmpty: {type: Boolean, required: false},
		value: {type: Object, required: false}
	},
	data() {
		return {
			request: (q) =>
				this.$axios.get("stock-items", {
					params: {sort: "nav_id,name", filter: {search: q}}
				})
		};
	},
	methods: {
		update(item) {
			this.$emit("input", item);
		}
	}
};
</script>
